<template>
	<Result
		:title="$t('title')"
		:sub-title="secondaryTitle"
		:content="$t('content')"
		:secondary-content="$t('secondaryContent')"
		secondary-content-class="secondary-content"
		:button="$t('btn')"
		:automation-id="getAutomationId('changeEmailVerify')"
	/>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import IdMixin from '@/mixins/id';
import Result from '@/components/result/Result';

@Component({
	name: 'ChangeEmailVerifyPage',
	mixins: [IdMixin],
	components: {
		Result
	}
})
export default class ChangeEmailVerifyPage extends Vue {
	get secondaryTitle() {
		return this.$t('subTitle', { 0: sessionStorage.getItem('newEmail') });
	}
}
</script>
<style lang="scss" scoped>
* ::v-deep .content-one {
	margin-right: 0;
	margin-left: 0;
}
* ::v-deep .secondary-content {
	margin-bottom: 0;
}
</style>
<i18n>
{
  "en": {
    "title": "Verify your email",
    "subTitle": "We've sent a link to:<br/><b>{0}</b>",
    "content": "<b>Click the link in your email to confirm your updated email address</b>",
    "secondaryContent": "<b>Didn't get the email?</b> There may be an issue with the email you provided. Try logging in with your old address and try the change again, or contact us.",
    "btn": "Go to login"
  },
  "fr": {
    "title": "Confirmer votre adresse courriel",
    "subTitle": "Nous avons envoyé un lien à :</br><b>{0}</b>",
    "content": "<b>Cliquez sur le lien figurant dans votre courriel pour confirmer la mise à jour de votre adresse électronique.</b>",
    "secondaryContent": "<b>Vous n'avez pas reçu le courriel?</b> Il se peut qu'il y ait un problème avec l'adresse électronique que vous avez fournie. Essayez de vous connecter avec votre ancienne adresse et réessayez de faire le changement, ou communiquez avec nous.",
    "btn": "Aller à la page de connexion"
  }
}
</i18n>
