<!-- 
  Base component to display content.  Has a top label and a slot for other content.

  Example when to use:  To display a member's personal information, such as language preference.

  Example how to use:

  <base-display-group automation-id="1" label="Preferred language">English</base-display-group>
-->

<template>
	<div class="display-group" :data-test-automation-id="getAutomationId('displayGroup')">
		<label :id="`lblTitle-${label}`" :for="`displayGroup${label}`" class="display-group-label">
			{{ label }}
		</label>
		<section
			:id="`displayGroup${label}`"
			:aria-labelledby="`lblTitle-${label}`"
			class="display-group-text"
		>
			<slot></slot>
		</section>
	</div>
</template>

<script>
import IdMixin from '@/mixins/id';

export default {
	name: 'BaseDisplayGroup',
	mixins: [IdMixin],
	inheritAttrs: false,
	props: {
		label: {
			type: String,
			required: true
		}
	}
};
</script>
