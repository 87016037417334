<template>
	<BaseCard automation-id="changeEmail" :title="$t('title')">
		<BCardText>{{ $t('instructions') }}</BCardText>
		<BaseDisplayGroup automation-id="currentEmail" :label="$t('label.currentEmail')">
			<address>{{ currentEmail }}</address>
		</BaseDisplayGroup>

		<BaseForm class="col-lg-6" automation-id="changeEmailForm">
			<EmailInput
				:label="$t('label.newEmail')"
				:value="email"
				requires-current-email-check
				:current-email="currentEmail"
				:automation-id="getAutomationId('newEmail')"
				@input="input"
			/>
			<BaseButton
				ref="btnSubmit"
				:label="$t('button.submit')"
				variant="primary"
				:disabled="disableSubmit"
				:automation-id="getAutomationId('submit')"
				type="submit"
				@click="onSave"
			/>
			<BaseButton
				ref="btnCancel"
				:label="$t('button.cancel')"
				variant="link"
				:automation-id="getAutomationId('cancel')"
				@click="onCancel"
			/>
		</BaseForm>
	</BaseCard>
</template>

<script>
import { BCardText } from 'bootstrap-vue';
import IdMixin from '@/mixins/id';
// constants
import * as Routes from '@/constants/Routes';
// components
import BaseButton from '@/components/common/base/BaseButton.vue';
import BaseCard from '@/components/common/card/BaseCard.vue';
import BaseDisplayGroup from '@/components/common/base/BaseDisplayGroup.vue';
import BaseForm from '@/components/common/base/BaseForm.vue';
import EmailInput from '@/components/common/EmailInput.vue';

export default {
	name: 'ChangeEmail',
	components: {
		BCardText,
		BaseButton,
		BaseCard,
		BaseDisplayGroup,
		BaseForm,
		EmailInput
	},
	mixins: [IdMixin],

	props: {
		email: {
			type: String,
			required: true
		},
		disableSubmit: {
			type: Boolean,
			default: false
		}
	},

	computed: {
		/** Load user email from session variable	 */
		currentEmail() {
			return sessionStorage.getItem('email');
		}
	},

	methods: {
		/** input event for new email */
		input(value) {
			this.$emit('input', value);
		},

		/** cancel and returns the user to the previous screen */
		onCancel() {
			this.$router.push({ name: Routes.PERSONAL_INFORMATION });
		},

		onSave() {
			this.$emit('save');
		}
	}
};
</script>

<style lang="scss" scoped>
.col-lg-6 {
	padding-left: 0;
	padding-right: 0;
}
</style>

<i18n>
{
  "en": {
    "title": "Need to change or update your current email address?",
    "instructions": "Enter your new email address below, and we’ll send you a link to confirm the update.",
    "label": {
      "currentEmail": "Current email",
      "newEmail": "New email"
    },
    "button": {
      "submit": "Submit",
      "cancel": "Cancel"
    }
  },
  "fr": {
    "title": "Vous devez modifier ou mettre à jour votre adresse électronique actuelle?",
    "instructions": "Saisissez votre nouvelle adresse électronique ci-dessous, et nous vous enverrons un lien pour confirmer la mise à jour.",
    "label": {
      "currentEmail": "Adresse électronique actuelle",
      "newEmail": "Nouvelle adresse électronique"
    },
    "button": {
      "submit": "Soumettre",
      "cancel": "Annuler"
    }
  }
}
</i18n>
