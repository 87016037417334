<template>
	<ManageYourAccountTemplate :page-title="$t('title')">
		<ChangeEmail
			:email="email"
			automation-id="changeEmail"
			:disable-submit="disableSubmit"
			@input="updateEmail"
			@save="saveEmail"
		/>
	</ManageYourAccountTemplate>
</template>

<script>
import BreadcrumbsManager from '@/mixins/BreadcrumbsManager';
import { DASHBOARD, MANAGE_YOUR_ACCOUNT, CHANGE_EMAIL_VERIFY } from '@/constants/Routes';
import ManageYourAccountTemplate from '../ManageYourAccountTemplate.vue';
import ChangeEmail from '@/components/manage-your-account/personal-information/ChangeEmail.vue';
import ContactInfo from '@/models/ContactInfo';

export default {
	name: 'ChangeEmailPage',
	components: {
		ChangeEmail,
		ManageYourAccountTemplate
	},
	mixins: [BreadcrumbsManager],
	data() {
		return {
			email: '',
			disableSubmit: false
		};
	},

	created() {
		this.setBreadcrumbPath(
			[
				{ text: this.$t('breadcrumb.home', 'en'), to: { name: DASHBOARD } },
				{ text: this.$t('breadcrumb.manageYourAccount', 'en'), to: { name: MANAGE_YOUR_ACCOUNT } },
				{ text: this.$t('breadcrumb.personalInformation', 'en') }
			],
			[
				{ text: this.$t('breadcrumb.home', 'fr'), to: { name: DASHBOARD } },
				{ text: this.$t('breadcrumb.manageYourAccount', 'fr'), to: { name: MANAGE_YOUR_ACCOUNT } },
				{ text: this.$t('breadcrumb.personalInformation', 'fr') }
			]
		);
	},

	methods: {
		updateEmail: function (value) {
			this.email = value;
		},
		async saveEmail() {
			this.$store.dispatch('updateLoading', true);
			this.disableSubmit = true;
			var response = await ContactInfo.updateEmail(
				sessionStorage.getItem('email'),
				this.email,
				this.$root.$i18n.locale,
				sessionStorage.getItem('apiToken')
			);
			this.$store.dispatch('updateLoading', false);
			this.disableSubmit = false;
			if (response?.status <= 204) {
				const securityPolicy = sessionStorage.getItem('securityPolicy');
				sessionStorage.clear();
				sessionStorage.setItem('newEmail', this.email);
				const brand = JSON.parse(securityPolicy).brand ? JSON.parse(securityPolicy).brand : 'MBC';
				this.$router.push({
					name: CHANGE_EMAIL_VERIFY,
					query: { brand: brand }
				});
			} else if (response?.status === 400) {
				this.$store.dispatch('error', {
					name: 'change-email-error',
					err: this.$t('malformedEmail')
				});
			} else if (response?.status === 409) {
				this.$store.dispatch('error', {
					name: 'change-email-error',
					err: response.data?.message
				});
			} else {
				this.$store.dispatch('error', { name: 'generic', err: 'generic' });
			}
		}
	}
};
</script>

<i18n>
{
  "en": {
    "breadcrumb": {
      "home": "Home",
      "manageYourAccount": "Manage your account",
      "personalInformation": "Personal Information"
    },
    "title": "Personal Information",
	"malformedEmail": "It seems your email is incomplete. Please re-enter your email address (example: name@domain.com)."
  },
  "fr": {
    "breadcrumb": {
      "home": "Accueil",
      "manageYourAccount": "Gérer votre compte",
      "personalInformation": "Renseignements personnels"
    },
    "title": "Renseignements personnels",
	"malformedEmail": "Veuillez entrer une adresse courriel dans le bon format (p. ex. : nom@domaine.com)."
  }
}
</i18n>
